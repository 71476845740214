import React, { useEffect } from 'react'
import { message } from 'antd'
import useGMT from '../../../hooks/useGTM'
import { HUBSPOT_INTEGRATION } from '../../../data'

const NewsLetter = () => {
  const { pushSubscribeToNewsletterEvent } = useGMT()
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        loadSubscribeForm()
      }
    })
  }, [])

  function loadSubscribeForm() {
    if (document.getElementById('hubspotForm')) {
      document.getElementById('hubspotForm').innerHTML = ''

      window.hbspt.forms.create({
        region: 'na1',
        portalId: HUBSPOT_INTEGRATION.PORTAL_ID,
        formId: HUBSPOT_INTEGRATION.NEWSLETTER_FORM_ID,
        target: '#hubspotForm',
        onFormSubmitted: function () {
          console.log('Form-Submitted')
          message.success('Thanks for subscribing!')
          pushSubscribeToNewsletterEvent()
          // loadSubscribeForm()
        },
      })
    }
  }

  return (
    <div className="px-[30px] py-10 lg:py-32 bg-themePink w-full mt-8 mb-16">
      <div className="container mx-auto flex flex-col justify-center items-center">
        <h1 className="font-playfair text-[26px] lg:text-4xl font-normal mb-2">
          Subscribe
        </h1>
        <p className="font-normal font-sans text-base py-2">
          By subscribing to our mailing list, you agree to receive marketing
          communications, updates, and relevant information about our services
          and offers. We are committed to respecting your preferences, and you
          can unsubscribe from these communications at any time by following the
          link provided in our emails or contacting our support team. Your
          privacy is important to us, and we ensure that your information is
          safe and handled in accordance with{' '}
          <a href="/privacy-policy" target="_blank">
            MedReps' privacy policy
          </a>
          .
        </p>
        <div id="hubspotForm"></div>
      </div>
    </div>
  )
}
export default NewsLetter
